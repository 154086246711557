
var ua = navigator.userAgent.toLowerCase();
var isIE = /msie/.test(ua);
var isWinXP = /windows nt 5.1/.test(ua);
var isWinVista = /windows nt 6.0/.test(ua);
var isWin7 = /windows nt 6.1/.test(ua);
var isMacOSX = (/mac os x/.test(ua));
var doesSupportHtml5DateInput;

/**
 * Layered elements can get obscured by <object>, <embed>, <select> or sometimes even <iframe>
 * on older versions of Windows + Internet Explorer.
 * From manual testing, all IE versions on Windows 7 appear to have the bug,
 * but no IE versions on Windows 8 have it.
 */
export function needsLayeringShim () {
    return isIE && (isWinXP || isWinVista || isWin7);
}

export function supportsVoiceOver () {
    return isMacOSX;
}

export function supportsDateField () {
    if (typeof doesSupportHtml5DateInput === 'undefined') {
        var el = document.createElement('input');
        el.setAttribute('type', 'date');
        doesSupportHtml5DateInput = el.type === 'date';
    }
    return doesSupportHtml5DateInput;
}
