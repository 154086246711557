import $ from './jquery';
import amdify from './internal/amdify';
import skate from './internal/skate';

function isNestedAnchor(trigger, target) {
    var $closestAnchor = $(target).closest('a[href]', trigger);
    return !!$closestAnchor.length && $closestAnchor[0] !== trigger;
}

function findControlled(trigger) {
    return document.getElementById(trigger.getAttribute('aria-controls'));
}

function triggerMessage(trigger, e) {
    if (trigger.isEnabled()) {
        var component = findControlled(trigger);
        if (component && component.message) {
            component.message(e);
        }
    }
}

skate('data-aui-trigger', {
    type: skate.type.ATTRIBUTE,
    events: {
        click: function (trigger, e) {
            if (!isNestedAnchor(trigger, e.target)) {
                triggerMessage(trigger, e);
                e.preventDefault();
            }
        },
        mouseenter: function (trigger, e) {
            triggerMessage(trigger, e);
        },
        mouseleave: function (trigger, e) {
            triggerMessage(trigger, e);
        },
        focus: function (trigger, e) {
            triggerMessage(trigger, e);
        },
        blur: function (trigger, e) {
            triggerMessage(trigger, e);
        }
    },
    prototype: {
        disable: function () {
            this.setAttribute('aria-disabled', 'true');
        },
        enable: function () {
            this.setAttribute('aria-disabled', 'false');
        },
        isEnabled: function () {
            return this.getAttribute('aria-disabled') !== 'true';
        }
    }
});

amdify('aui/trigger');
