import format from './format';
import globalize from './internal/globalize';
import keys from './internal/i18n/aui';

/**
 * Returns the value defined in AJS.I18n.keys for the given key. If AJS.I18n.keys does not exist, or if the given key does not exist,
 * the key is returned - this could occur in plugin mode if the I18n transform is not performed;
 * or in flatpack mode if the i18n JS file is not loaded.
 */
var i18n = {
    keys: keys,
    getText: function (key) {
        var params = Array.prototype.slice.call(arguments, 1);

        if (Object.prototype.hasOwnProperty.call(this.keys, key)) {
            return format.apply(null, [this.keys[key]].concat(params));
        }

        return key;
    }
};

globalize('I18n', i18n);

export default i18n;
